<template>
	<!--begin::Card-->
	<div class="card card-custom">
		<!--begin::Header-->
		<div class="card-header py-3">
			<div class="card-title align-items-start flex-column">
				<h3 class="card-label font-weight-bolder text-dark">Change Password</h3>
				<span class="text-muted font-weight-bold font-size-sm mt-1">Change your account password</span>
			</div>
			<div class="card-toolbar">
				<button
					:disabled="!validatePW"
					type="submit"
					:class="{ 'spinner spinner-light spinner-right': submitting }"
					class="btn btn-success mr-2"
					@click="save()"
				>
					Save Changes
				</button>
				<button type="reset" class="btn btn-secondary" @click="cancel()">
					Cancel
				</button>
			</div>
		</div>
		<!--end::Header-->
		<!--begin::Form-->
		<form class="form" id="kt_password_change_form">
			<div class="card-body">
				<b-alert v-if="success" show variant="success" class="mb-10 text-center font-weight-bold">Password Successfully Changed!</b-alert>
				<b-alert v-if="error" show variant="danger" class="mb-10 text-center font-weight-bold">
					{{ errorMsg }}
				</b-alert>
				<!-- <div class="form-group row">
					<label class="col-xl-3 col-lg-3 col-form-label text-alert">Current Password</label>
					<div class="col-lg-9 col-xl-6">
						<input
							type="password"
							class="form-control form-control-lg form-control-solid mb-2"
							value=""
							v-model="currentPW"
							placeholder="Current password"
							name="current_password"
							ref="current_password"
						/>
						
					</div>
				</div> -->
				<div v-if="!success">
				<div class="form-group row" >
					<label class="col-xl-3 col-lg-3 col-form-label text-alert">New Password</label>
					<div class="col-lg-9 col-xl-6">
						<input
							type="password"
							class="form-control form-control-lg form-control-solid"
							value=""
							v-model="newPW"
							placeholder="New password"
							name="new_password"
							ref="new_password"
						/>
						<span class="form-text" :class="{ hasError: !validatePWLength, muted: validatePWLength }">Must be at least 8 characters long.</span>
					</div>
				</div>
				<div class="form-group row">
					<label class="col-xl-3 col-lg-3 col-form-label text-alert">Verify Password</label>
					<div class="col-lg-9 col-xl-6">
						<input
							type="password"
							class="form-control form-control-lg form-control-solid"
							value=""
							v-model="verifyPW"
							placeholder="Verify password"
							name="verify_password"
							ref="verify_password"
						/>
						<span v-if="!hasUpperCase" class="form-text text-danger">Password must contain an uppercase letter</span>
						<span v-if="!hasLowerCase" class="form-text text-danger">Password must contain a lowercase letter</span>
						<span v-if="!hasNumber" class="form-text text-danger">Password must contain a number</span>
						<span v-if="!hasSpecialChar" class="form-text text-danger">Password must contain a special character</span>
						<span v-if="!isPasswordMatching" class="form-text text-danger">Passwords must match</span>
					</div>
				</div>
				</div>
			</div>
		</form>
		<!--end::Form-->
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { LOGIN } from '@/core/services/store/auth.module';
// import { UPDATE_PASSWORD } from "@/core/services/store/auth.module";

export default {
	name: 'ChangePassword',
	data() {
		return {
			currentPW: '',
			newPW: '',
			verifyPW: '',
			success: false,
			error: false,
			errorMsg: null,
			submitting: false,
		};
	},
	methods: {
		save() {
			this.error = false;
			this.success = false;
			this.submitting = true;
			this.$http
				.post('auth0/password', { new_pass: this.newPW })
				.then(res => {
					if (res.data.data.result == 1) {
						this.success = true;
						// this.$store.dispatch(LOGIN, { username: this.currentUser.username, password: this.newPW });
					}
					if (res.data.data.result == 0) {
						console.log('error updating pw')
					}
					this.currentPW = '';
					this.newPW = '';
					this.verifyPW = '';
					this.submitting = false;
				})
				.catch(err => {
					if (err.response.status == 400) {
						this.submitting = false;
						this.error = true;
						this.errorMsg = err.response.data.data.message;
					}
					console.log('Password Update Error:', err);
				});
		},
		cancel() {
			this.currentPW = '';
			this.newPW = '';
			this.verifyPW = '';
			this.success = false;
			this.pwError = false;
			this.badPw = false;
		},
	},
	computed: {
		...mapGetters(['currentUser']),
		validatePWLength() {
			if (this.newPW.length > 7) {
				return true;
			} else {
				return false;
			}
		},
		validatePW() {
			const hasUpperCase = /[A-Z]/.test(this.newPW);
			const hasLowerCase = /[a-z]/.test(this.newPW);
			const hasNumber = /[0-9]/.test(this.newPW);
			const hasSpecialChar = /[\W_]/.test(this.newPW); // \W is non-word character, _ is underscore

			return this.newPW === this.verifyPW &&
				this.newPW.length > 7 &&
				hasUpperCase &&
				hasLowerCase &&
				hasNumber &&
				hasSpecialChar;
		},
		hasUpperCase() {
        	return /[A-Z]/.test(this.newPW);
		},
		hasLowerCase() {
			return /[a-z]/.test(this.newPW);
		},
		hasNumber() {
			return /[0-9]/.test(this.newPW);
		},
		hasSpecialChar() {
			return /[\W_]/.test(this.newPW);
		},
		isPasswordMatching() {
			return this.newPW === this.verifyPW;
		},
		isPasswordValid() {
			return this.isLengthValid && this.hasUpperCase && this.hasLowerCase && this.hasNumber && this.hasSpecialChar && this.isPasswordMatching;
		},
	},
};
</script>

<style scoped>
.hasError {
	color: red;
}
.muted {
	color: #c2c3cb;
}
</style>
