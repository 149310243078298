<template>
	<div class="card card-custom">
		<!--begin::Header-->
		<div class="card-header py-3">
			<div class="card-title align-items-start flex-column">
				<h3 class="card-label font-weight-bolder text-dark">Emailed Reports</h3>
			</div>
			<div class="card-toolbar"></div>
		</div>
		<!--end::Header-->
		<!--begin::Form-->
		<form class="form">
			<div class="card-body">
				<div class="row">
					<label class="col-xl-3"></label>
					<div class="col-lg-9 col-xl-6">
						<h5 class="font-weight-bold mb-6">Report Frequency:</h5>
					</div>
				</div>
				<div class="form-group row">
					<label class="col-xl-3 col-lg-3 col-form-label text-right d-flex align-items-center justify-content-end" style="padding:0;">Daily: </label>
					<div class="col-lg-9 col-xl-6">
						<span class="switch switch-sm">
							<label>
								<input @change="saveReports(true)" type="checkbox" v-model="reports.daily" name="email_notification_1" />
								<span></span>
							</label>
							<div class="ml-3" style="min-width:80px;">
								<span v-if="reports.daily"> Enabled</span>
								<span v-else> Disabled</span>
							</div>
							<button
								@click.prevent="runReports('daily')"
								:disabled="dprogress !== null"
								class="btn btn-sm btn-light-primary font-weight-bolder ml-5"
							>
								<span v-if="dprogress != null && dprogress < 100">Running...</span>
								<span v-if="dprogress == null">Run now</span>
							</button>
							<div v-show="dprogress" class="ml-5 font-weight-bold">{{ dprogress }}% complete.</div>
							<div v-if="reports.dprogress == 100" class="ml-5 font-weight-bold">Report Sent</div>
						</span>
					</div>
				</div>
				<div class="form-group row">
					<label class="col-xl-3 col-lg-3 col-form-label text-right d-flex align-items-center justify-content-end" style="padding:0;">Weekly: </label>
					<div class="col-lg-9 col-xl-6">
						<span class="switch switch-sm">
							<label>
								<input @change="saveReports(true)" type="checkbox" v-model="reports.weekly" name="email_notification_2" />
								<span></span>
							</label>
							<div class="ml-3" style="min-width:80px;">
								<span v-if="reports.weekly"> Enabled</span>
								<span v-else> Disabled</span>
							</div>
							<button
								@click.prevent="runReports('weekly')"
								:disabled="wprogress !== null"
								class="btn btn-sm btn-light-primary font-weight-bolder ml-5"
							>
								<span v-if="wprogress != null && wprogress < 100">Running...</span>
								<span v-if="wprogress == null">Run now</span>
							</button>
							<div v-show="wprogress" class="ml-5 font-weight-bold">{{ wprogress }}% complete.</div>
							<div v-if="reports.wprogress == 100" class="ml-5 font-weight-bold">Report Sent</div>
						</span>
					</div>
				</div>
				<div class="form-group row">
					<label class="col-xl-3 col-lg-3 col-form-label text-right d-flex align-items-center justify-content-end" style="padding:0;"
						>Monthly:
					</label>
					<div class="col-lg-9 col-xl-6">
						<span class="switch switch-sm">
							<label>
								<input @change="saveReports(true)" type="checkbox" v-model="reports.monthly" name="email_notification_3" />
								<span></span>
							</label>
							<div class="ml-3" style="min-width:80px;">
								<span v-if="reports.monthly"> Enabled</span>
								<span v-else> Disabled</span>
							</div>
							<button
								@click.prevent="runReports('monthly')"
								:disabled="mprogress !== null"
								class="btn btn-sm btn-light-primary font-weight-bolder ml-5"
							>
								<span v-if="mprogress != null && mprogress < 100">Running...</span>
								<span v-if="mprogress == null">Run now</span>
							</button>
							<div v-show="mprogress" class="ml-5 font-weight-bold">{{ mprogress }}% complete.</div>
							<div v-if="reports.mprogress == 100" class="ml-5 font-weight-bold">Report Sent</div>
						</span>
					</div>
				</div>
				<div class="form-group row">
					<label class="col-xl-3 col-lg-3 col-form-label text-right d-flex align-items-center justify-content-end" style="padding:0;">Yearly: </label>
					<div class="col-lg-9 col-xl-6">
						<span class="switch switch-sm">
							<label>
								<input @change="saveReports(true)" type="checkbox" v-model="reports.yearly" name="email_notification_4" />
								<span></span>
							</label>
							<div class="ml-3" style="min-width:80px;">
								<span v-if="reports.yearly"> Enabled</span>
								<span v-else> Disabled</span>
							</div>
							<button
								@click.prevent="runReports('yearly')"
								:disabled="yprogress !== null"
								class="btn btn-sm btn-light-primary font-weight-bolder ml-5"
							>
								<span v-if="yprogress != null && yprogress < 100">Running...</span>
								<span v-if="yprogress == null">Run now</span>
							</button>
							<div v-show="yprogress" class="ml-5 font-weight-bold">{{ yprogress }}% complete.</div>
							<div v-if="reports.yprogress == 100" class="ml-5 font-weight-bold">Report Sent</div>
						</span>
					</div>
				</div>
				<div class="separator separator-dashed my-10"></div>
				<div class="row">
					<label class="col-xl-3"></label>
					<div class="col-lg-9 col-xl-6">
						<h5 class="font-weight-bold mb-6">Reports will be emailed to:</h5>
					</div>
				</div>
				<div class="form-group row">
					<label class="col-xl-3 col-lg-3 col-form-label text-right"></label>
					<div class="col-lg-9 col-xl-6">
						<div class="font-weight-bolder">
							{{ this.currentUser.email }}
							<i class="fa fa-edit ml-3" style="color:#3699FF; cursor: pointer;"></i>
						</div>
					</div>
				</div>
			</div>
		</form>
		<!--end::Form-->
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'EmailSettings',
	data() {
		return {
			dstatus: null,
			wstatus: null,
			mstatus: null,
			ystatus: null,
			dprogress: null,
			wprogress: null,
			mprogress: null,
			yprogress: null,
			reports: {
				daily: null,
				weekly: null,
				monthly: null,
				yearly: null,
			},
		};
	},
	components: {},
	computed: {
		...mapGetters(['currentUser']),
	},
	methods: {
		runReports(report) {
			if (report == 'daily') {
				this.dprogress = 0;
				this.reports.dprogress = 0;
			}
			if (report == 'weekly') {
				this.wprogress = 0;
				this.reports.wprogress = 0;
			}
			if (report == 'monthly') {
				this.mprogress = 0;
				this.reports.mprogress = 0;
			}
			if (report == 'yearly') {
				this.yprogress = 0;
				this.reports.yprogress = 0;
			}
			this.saveReports(false);
			this.getReportProgress();
		},
		getReports() {
			this.$http.get('emailedreports').then(res => {
				let reports = res.data.data;
				this.reports = reports;
				this.updateReportStatus(reports);
				if (this.dprogress == null && this.wprogress == null && this.mprogress == null && this.yprogress == null) {
					this.stopProgressFetch();
				}
			});
		},
		getReportProgress() {
			this.reportProgress = setInterval(
				function() {
					this.getReports();
				}.bind(this),
				5000
			);
		},
		stopProgressFetch() {
			clearInterval(this.reportProgress);
		},
		saveReports(sent) {
			this.$http.post('emailedreports', { data: this.reports }).then(res => {
				this.reports = res.data.data;
				if (sent !== true) {
					this.updateReportStatus(res.data.data);
				}
			});
		},
		updateReportStatus(reports) {
			this.dprogress = reports.dprogress;
			this.wprogress = reports.wprogress;
			this.mprogress = reports.mprogress;
			this.yprogress = reports.yprogress;
			if (reports.dprogress == 100) {
				this.dstatus = 'Report Sent';
				this.dprogress = null;
				this.saveReports(true);
			}
			if (reports.wprogress == 100) {
				this.wstatus = 'Report Sent';
				this.wprogress = null;
				this.saveReports(true);
			}
			if (reports.mprogress == 100) {
				this.mstatus = 'Report Sent';
				this.mprogress = null;
				this.saveReports(true);
			}
			if (reports.yprogress == 100) {
				this.ystatus = 'Report Sent';
				this.yprogress = null;
				this.saveReports(true);
			}
		},
	},
	beforeMount() {
		if (this.currentUser.reports) {
			this.reports.daily = this.currentUser.reports.daily;
			this.reports.weekly = this.currentUser.reports.weekly;
			this.reports.monthly = this.currentUser.reports.monthly;
			this.reports.yearly = this.currentUser.reports.yearly;
		}
	},
	mounted() {
		// this.$store.dispatch(GET_USER_PROFILE);
	},
	beforeDestroy() {
		this.stopProgressFetch();
	},
};
</script>

<style scoped></style>
