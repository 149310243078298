<template>
	<div class="card card-custom">
		<!-- Table::begin -->
		<div id="device-list" class="card card-custom card-stretch gutter-b">
			<div class="card-header border-0 pt-3 d-flex align-items-center" style="min-height: 40px">
				<div>
					<h3 class="card-title align-items-start flex-column">
						<span class="card-label font-weight-bolder"> Report History </span>
					</h3>
				</div>
			</div>
			<div class="card-body pt-2 pb-0" style="max-height: 600px; overflow-y: scroll">
				<ITCSpinner :loaded="loaded">
					<b-table :items="reports" :fields="fields" striped>
						<template #cell(name)="data">
							{{ data.item.name }}
						</template>
						<template #cell(username)="data">
							{{ data.item.username }}
						</template>
						<template #cell(filename)="data" style="cursor:pointers;">
							<a target="_blank" :href="`https://portal.itcglobal.com/reports/${data.item.filename}`" download>
								{{ data.item.filename }}
							</a>
						</template>
					</b-table>
				</ITCSpinner>
			</div>
		</div>
		<!-- Table::end -->
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import ITCSpinner from '@/view/content/loaders/itcSpinner.vue';

export default {
	name: 'EmailSettings',
	data() {
		return {
			loaded: false,
			reports: [],
			fields: [
				{ key: 'name', label: 'Created' },
				{ key: 'username', label: 'User' },
				{ key: 'filename', label: 'Filename' },
			],
		};
	},
	components: {
		ITCSpinner,
	},
	computed: {
		...mapGetters(['currentUser']),
	},
	mounted() {
		this.$http
			.get('reporthistory')
			.then(res => {
				this.reports = res.data.data;
				this.loaded = true;
			})
			.catch(err => {
				console.log('Report History Error:', err);
			});
	},
};
</script>

<style scoped></style>
