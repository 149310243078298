<template>
	<div class="d-flex flex-row">
		<div class="flex-row-auto offcanvas-mobile w-300px w-xl-350px" id="kt_profile_aside">
			<div class="card card-custom card-stretch">
				<div class="card-body pt-4 pt-9">
					<div class="d-flex justify-content-end"><!-- <KTDropdown2></KTDropdown2> --><!-- future feature --></div>
					<div class="d-flex align-items-center">
						<div class="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
							<img class="symbol-label" :src="currentUserAvatar ? currentUserAvatar : '/media/svg/avatars/default-avatar.png'" alt="avatar" />
							<i class="symbol-badge bg-success"></i>
						</div>
						<div>
							<a class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">{{ currentUser.name }}</a>
							<div class="#">{{ currentUser.username }}</div>
							<div class="text-muted text-uppercase">{{ currentUser.role }}</div>
						</div>
					</div>
					<!--end::User-->
					<!--begin::Contact-->
					<div class="py-9">
						<div class="d-flex align-items-center justify-content-between mb-2">
							<span class="font-weight-bold mr-2">Email:</span>
							<a class="text-muted text-hover-primary">{{ currentUser.email }}</a>
						</div>
						<div v-if="currentUser.role === 'useradmin' || currentUser.role === 'user'">
							<div class="d-flex align-items-center justify-content-between mb-2">
								<span class="font-weight-bold mr-2">Sites:</span>
								<span class="text-muted">{{ sitesCount }}</span>
							</div>
							<div class="d-flex align-items-center justify-content-between">
								<span class="font-weight-bold mr-2">VOIP:</span>
								<span class="text-muted">{{ currentUser.voipcount }}</span>
							</div>
						</div>
					</div>
					<!--end::Contact-->
					<!--begin::Nav-->
					<div class="navi navi-bold navi-hover navi-active navi-link-rounded" role="tablist">
						<!-- Account Information -->
						<div class="navi-item mb-2" style="cursor: pointer">
							<a :class="[{ active: active == 'info' }, 'navi-link py-4']" @click="setActiveTab('info')">
								<span class="navi-icon mr-2">
									<span class="svg-icon">
										<inline-svg src="media/svg/icons/General/User.svg" />
									</span>
								</span>
								<span class="navi-text font-size-lg">Account Information</span>
							</a>
						</div>

						<!-- Change Passowrd -->
						<div class="navi-item mb-2" style="cursor: pointer">
							<a :class="[{ active: active == 'pass' }, 'navi-link py-4']" @click="setActiveTab('pass')">
								<span class="navi-icon mr-2">
									<span class="svg-icon">
										<inline-svg src="media/svg/icons/Communication/Shield-user.svg" />
									</span>
								</span>
								<span class="navi-text font-size-lg">Change Password</span>
							</a>
						</div>

						<!-- Emailed Reports -->
						<div v-if="currentUser.role === 'useradmin' || currentUser.role === 'user'" class="navi-item mb-2" style="cursor: pointer">
							<a :class="[{ active: active == 'emailed' }, 'navi-link py-4']" @click="setActiveTab('emailed')">
								<span class="navi-icon mr-2">
									<span class="svg-icon">
										<inline-svg src="media/svg/icons/Communication/Mail-opened.svg" />
									</span>
								</span>
								<span class="navi-text font-size-lg">Emailed Reports</span>
							</a>
						</div>

						<!-- Report History -->
						<div
							v-if="currentUser.role === 'admin' || currentUser.role === 'noc' || currentUser.role === 'staff'"
							class="navi-item mb-2"
							style="cursor: pointer"
						>
							<a :class="[{ active: active == 'reports' }, 'navi-link py-4']" @click="setActiveTab('reports')">
								<span class="navi-icon mr-2">
									<span class="svg-icon">
										<inline-svg src="media/svg/icons/Files/File.svg" />
									</span>
								</span>
								<span class="navi-text font-size-lg">Report History</span>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!--begin::Content-->
		<div class="flex-row-fluid ml-lg-8">
			<b-tabs class="hide-tabs" v-model="tabIndex">
				<b-tab :active="active == 'info'">
					<KTAccountInformation></KTAccountInformation>
				</b-tab>
				<b-tab :active="active == 'pass'">
					<KTChangePassword></KTChangePassword>
				</b-tab>
				<b-tab :active="active == 'emailed'">
					<KTEmailSettings></KTEmailSettings>
				</b-tab>
				<b-tab :active="active == 'reports'">
					<ReportHistory></ReportHistory>
				</b-tab>
			</b-tabs>
		</div>
		<!--end::Content-->
	</div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import { GET_USER_PROFILE } from '@/core/services/store/profile.module';
import { mapGetters } from 'vuex';
import KTDropdown2 from '@/view/pages/profile/Dropdown2';
import KTAccountInformation from '@/view/pages/profile/AccountInformation';
import KTChangePassword from '@/view/pages/profile/ChangePassword';
import KTEmailSettings from '@/view/pages/profile/EmailSettings';
import ReportHistory from '@/view/pages/profile/ReportHistory';

export default {
	name: 'Profile',
	components: {
		KTDropdown2,
		KTAccountInformation,
		KTChangePassword,
		KTEmailSettings,
		ReportHistory,
	},
	data() {
		return {
			tabIndex: 0,
			active: 'info',
		};
	},
	computed: {
		...mapGetters(['currentUser', 'currentUserAvatar']),
		sitesCount() {
			if (this.currentUser.sites) {
				return this.currentUser.sites.length;
			} else {
				return 0;
			}
		},
	},
	methods: {
		setActiveTab(tab) {
			this.active = tab;
		},
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Profile' }]);
		this.$store.dispatch(GET_USER_PROFILE);
	},
};
</script>

<style scoped>
::v-deep .hide-tabs > div:not(.tab-content) {
	display: none;
}
</style>
