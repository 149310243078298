<template>
	<!--begin::Card-->
	<div class="card card-custom">
		<!--begin::Header-->
		<div class="card-header py-3">
			<div class="card-title align-items-start flex-column">
				<h3 class="card-label font-weight-bolder text-dark">
					Account Information
				</h3>
				<span class="text-muted font-weight-bold font-size-sm mt-1"
					>Update your account information</span
				>
			</div>
			<div class="card-toolbar">
				<button type="reset" class="btn btn-success mr-2" @click="save()" ref="kt_save_changes">
					Save Changes
				</button>
				<button type="reset" class="btn btn-secondary" @click="cancel()">
					Cancel
				</button>
			</div>
		</div>
		<!--end::Header-->
		<!--begin::Form-->
		<form class="form">
			<!--begin::Body-->
			<div class="card-body">
				<div class="row">
					<label class="col-xl-3"></label>
					<div class="col-lg-9 col-xl-6">
						<h5 class="font-weight-bold mb-6">User Info</h5>
					</div>
				</div>
				<!-- Avatar updater for profile -->
				<!-- <div class="form-group row">
					<label class="col-xl-3 col-lg-3 col-form-label text-right">Avatar</label>
					<div class="col-lg-9 col-xl-6">
						<div class="image-input image-input-outline" id="kt_profile_avatar">
							<div class="image-input-wrapper" :style="{ backgroundImage: `url(${photo})` }"></div>
							<label
								class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
								data-action="change"
								data-toggle="tooltip"
								title=""
								data-original-title="Change avatar"
							>
								<i class="fa fa-pen icon-sm text-muted"></i>
								<input
									type="file"
									name="profile_avatar"
									accept=".png, .jpg, .jpeg"
									@change="onFileChange($event)"
								/>
								<input type="hidden" name="profile_avatar_remove" />
							</label>
							<span
								class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
								data-action="cancel"
								data-toggle="tooltip"
								title="Cancel avatar"
							>
								<i class="ki ki-bold-close icon-xs text-muted"></i>
							</span>
							<span
								class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
								data-action="remove"
								data-toggle="tooltip"
								title="Remove avatar"
								@click="current_photo = null"
							>
								<i class="ki ki-bold-close icon-xs text-muted"></i>
							</span>
						</div>
						<span class="form-text text-muted">Allowed file types: png, jpg, jpeg.</span>
					</div>
				</div> -->
				<div class="form-group row">
					<label class="col-xl-3 col-lg-3 col-form-label text-right">Name</label>
					<div class="col-lg-9 col-xl-6">
						<div class="input-group input-group-lg input-group-solid">
							<input
								ref="name"
								class="form-control form-control-lg form-control-solid"
								type="text"
								v-bind:value="currentUser.name"
							/>
							<div class="input-group-append">
								<span class="input-group-text"><i class="fa fa-edit"></i></span>
							</div>
						</div>
					</div>
				</div>
				<div class="form-group row">
					<label class="col-xl-3 col-lg-3 col-form-label text-right">Username</label>
					<div class="col-lg-9 col-xl-6">
						<div
							style="cursor: not-allowed;"
							class="form-control form-control-lg form-control-solid"
							
						>
						{{currentUser.username}}
						</div>
					</div>
				</div>
				<!-- <div class="form-group row">
					<label class="col-xl-3 col-lg-3 col-form-label text-right">Company Name</label>
					<div class="col-lg-9 col-xl-6">
						<input
							ref="company_name"
							class="form-control form-control-lg form-control-solid"
							type="text"
							v-bind:value="currentUser.company_name"
						/>
						<span class="form-text text-muted"
							>If you want your invoices addressed to a company. Leave blank to use your full
							name.</span
						>
					</div>
				</div> -->
				<div class="row">
					<label class="col-xl-3"></label>
					<div class="col-lg-9 col-xl-6">
						<h5 class="font-weight-bold mt-10 mb-6">Contact Info</h5>
					</div>
				</div>
				<!-- <div class="form-group row">
					<label class="col-xl-3 col-lg-3 col-form-label text-right">Contact Phone</label>
					<div class="col-lg-9 col-xl-6">
						<div class="input-group input-group-lg input-group-solid">
							<div class="input-group-prepend">
								<span class="input-group-text">
									<i class="la la-phone"></i>
								</span>
							</div>
							<input
								ref="phone"
								type="text"
								class="form-control form-control-lg form-control-solid"
								placeholder="Phone"
								v-bind:value="currentUser.phone"
							/>
						</div>
						<span class="form-text text-muted">We'll never share your email with anyone else.</span>
					</div>
				</div> -->
				<div class="form-group row">
					<label class="col-xl-3 col-lg-3 col-form-label text-right">Email Address</label>
					<div class="col-lg-9 col-xl-6">
						<div class="input-group input-group-lg input-group-solid">
							<div class="input-group-prepend">
								<span class="input-group-text">
									<i class="fa fa-envelope"></i>
								</span>
							</div>
							<input
								ref="email"
								type="text"
								class="form-control form-control-lg form-control-solid"
								placeholder="Email"
								v-bind:value="currentUser.email"
								disabled="true"
							/>
							<!-- <div class="input-group-append">
                                    <span class="input-group-text"><i class="fa fa-edit"></i></span>
							</div> -->
						</div>
					</div>
				</div>

				<div class="separator separator-dashed my-10"></div>

				<div class="row">
					<label class="col-xl-3"></label>
					<div class="col-lg-9 col-xl-6">
						<h5 class="font-weight-bold mb-6">Detailed Info</h5>
					</div>
				</div>
				<div class="form-group row">
					<label class="col-xl-3 col-lg-3 col-form-label text-right">Created</label>
					<div class="col-lg-9 col-xl-6">
						<div class="input-group input-group-lg input-group-solid">
							<div class="form-control form-control-lg form-control-solid">
								{{currentUser.DateAdded}}
							</div>
						</div>
					</div>
				</div>
				<div class="form-group row">
					<label class="col-xl-3 col-lg-3 col-form-label text-right">Last Login</label>
					<div class="col-lg-9 col-xl-6">
						<div class="input-group input-group-lg input-group-solid">
							<div class="form-control form-control-lg form-control-solid">
								{{currentUser.lastlogin}}
							</div>
						</div>
					</div>
				</div>
				<div class="form-group row">
					<label class="col-xl-3 col-lg-3 col-form-label text-right">Last IP Address</label>
					<div class="col-lg-9 col-xl-6">
						<div class="input-group input-group-lg input-group-solid">
							<div class="form-control form-control-lg form-control-solid">
								{{currentUser.lastip}}
							</div>
						</div>
					</div>
				</div>
				<div class="form-group row">
					<label class="col-xl-3 col-lg-3 col-form-label text-right">Password Changed</label>
					<div class="col-lg-9 col-xl-6">
						<div class="input-group input-group-lg input-group-solid">
							<div class="form-control form-control-lg form-control-solid">
								{{currentUser.password_changed}}
							</div>
						</div>
					</div>
				</div>

				<div class="separator separator-dashed my-10"></div>

				<div class="row" v-if="this.currentUser.role != 'user'">
					<label class="col-xl-3"></label>
					<div class="col-lg-9 col-xl-6">
						<h5 class="font-weight-bold mb-6">Default Page After Login</h5>
					</div>
				</div>
				<div class="form-group row" v-if="this.currentUser.role != 'user'">
					<label class="col-xl-3 col-lg-3 col-form-label text-right">Admin Page</label>
					<div class="col-sm-1" style="margin-top:8px;">
						<span class="switch switch-sm">
							<label>
								<input @change="save()" type="checkbox" v-model="adminfirst" name="adminfirstpage" />
								<span></span>
							</label>
						</span>
						<!-- <b-form-checkbox type="checkbox" id="checkbox" checked="currentUser.adminfirst" v-model="adminfirst"></b-form-checkbox> -->
					</div>
				</div>
				
			</div>
			<!--end::Body-->
		</form>
		<!--end::Form-->
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import { UPDATE_PERSONAL_INFO, GET_USER_INFO, UPDATE_USER_INFO } from "@/core/services/store/profile.module";

export default {
	name: "Information",
	data() {
		return {
			default_photo: "media/users/blank.png",
			current_photo: null,
			adminfirst: false,
		};
	},
	methods: {
		save() {
			this.$http.post('profile',{name: this.$refs.name.value, email: this.$refs.email.value, adminfirst: this.adminfirst})
			.catch(err => {
				console.log("Profile Save Error:", err);
			})

			let name = this.$refs.name.value;
			let email = this.$refs.email.value;
			let adminfirst = this.adminfirst;

			// set spinner to submit button
			const submitButton = this.$refs["kt_save_changes"];
			submitButton.classList.add("spinner", "spinner-light", "spinner-right");

			// dummy delay
			setTimeout(() => {
				// send update request
				this.$store.dispatch(UPDATE_USER_INFO, {
					name,
					email,
					adminfirst
				});

				submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
			}, 2000);
		},
		cancel() {
			this.$refs.name.value = this.currentUser.name;
			// this.$refs.username.value = this.currentUser.surname;
			this.$refs.company_name.value = this.currentUser.company_name;
			this.$refs.phone.value = this.currentUser.phone;
			this.$refs.email.value = this.currentUser.email;
			this.$refs.company_site.value = this.currentUser.company_site;
			this.current_photo = this.currentUser.photo;
		},
		onFileChange(e) {
			const file = e.target.files[0];

			if (typeof FileReader === "function") {
				const reader = new FileReader();

				reader.onload = event => {
					this.current_photo = event.target.result;
				};

				reader.readAsDataURL(file);
			} else {
				alert("Sorry, FileReader API not supported");
			}
		},
	},
	computed: {
		...mapGetters(["currentUser"]),
		photo() {
			return this.current_photo == null ? this.default_photo : this.current_photo;
		},
	},
	created() {

	},
	beforeMount() {
		this.adminfirst = this.currentUser.adminfirst;
	},
	mounted() {
		this.current_photo = this.currentUser.photo;
	}
};
</script>
